import {
  Link,
  LinkFilter,
  Navigation,
  NavigationFilter,
  NavigationItem,
  NavigationItemFilter,
} from '../../../types/contentful-api';
import { cleanNodeItem, resourceLink } from '@/helpers/resource-resolution';

import { ActionTree } from 'vuex';
import { DKSpaceOriginType } from '@/models/DKSpaceOrigin';
import { DKSpaceSource } from '@/models/DKSpaceSource';
import { LOCALE } from '@/store/app/models/Locale';
import { MenuSelectType } from '@/store/app/models/MenuSelectType';
import { NavigationState } from '@/store/navigation/index';
import { RouteLocation } from 'vue-router';
import { StringMap } from '@/models/StringMap';
import { Vue } from 'vue-facing-decorator';
import { currentPath } from '@/helpers/currentPath';
import { getEnv } from '@/env';
import { isAbsoluteLink } from '@/helpers/link';
import linkCollectionQuery from '@/api/contentful-api/graphql/linkCollection.graphql';
import localNavigationCollection from '@/api/contentful-api/graphql/navigationCollection.graphql';
import { localNavigationItemCollectionQuery } from '@/api/contentful-api/navigationItemCollectionQuery';
import navigationItemQuery from '@/api/contentful-api/graphql/navigationItem.graphql';
import { notEmpty } from '@/helpers/not-empty';

const contentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.defaultClient;

const globalContentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.clients.contentfulGraphQlGlobalClient;

export async function getLocalNavigationCollection(locale: LOCALE) {
  const localWhere: NavigationFilter = {
    menuSelect_in: Object.values(MenuSelectType),
  };

  const $collectionResponse = contentfulGraphQlClient().query({
    query: localNavigationCollection,
    variables: {
      locale: locale,
      preview: getEnv('ENABLE_PREVIEW') === 'true',
      where: localWhere,
    },
  });

  const $itemsCollectionResponse = contentfulGraphQlClient().query({
    query: localNavigationItemCollectionQuery(resourceLink('Popup')),
    variables: {
      locale: locale,
      preview: getEnv('ENABLE_PREVIEW') === 'true',
    },
  });

  const [collectionResponse, itemsCollectionResponse] = await Promise.all([
    $collectionResponse,
    $itemsCollectionResponse,
  ]);

  const localNavigations: Navigation[] =
    collectionResponse.data?.navigationCollection?.items || [];

  const localNavigationItems: NavigationItem[] =
    itemsCollectionResponse.data?.navigationItemCollection?.items || [];

  return { localNavigations, localNavigationItems };
}

const linkNotEmpty = (item: NavigationItem) => notEmpty(item.link);
const removeLocalizedLinkProperties = (item: NavigationItem) => {
  return {
    ...item,
    link: {
      sys: {
        id: item.link?.sys.id,
      },
      link: item.link?.link,
      page: item.link?.page,
    },
  };
};
const shouldBeLoadedFromGlobal = (link: Link) =>
  link.link && !link.page && !isAbsoluteLink(link.link) && link.sys.id;
const getUniqueLinks = (links1: Link[], links2: Link[]): Link[] => {
  return [
    ...new Map(
      [...links1, ...links2].map((item) => [item['link'], item])
    ).values(),
  ];
};

export const actions: ActionTree<NavigationState, any> = {
  async fetchNavigationItemBySlug(
    { commit },
    payload: { path: string; locale?: LOCALE }
  ) {
    const where: NavigationItemFilter = {
      link: {
        link: payload.path,
      },
    };
    const response = await contentfulGraphQlClient().query({
      query: navigationItemQuery,
      variables: {
        locale: payload.locale || 'en-US',
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });
    commit('setNavigationItem', response.data.navigationItems.items[0]);
  },
  async fetchNavigationItems(
    { commit },
    payload: {
      locale: LOCALE;
      $route: RouteLocation;
    }
  ) {
    const locale = payload.locale;
    const { localNavigations, localNavigationItems } =
      await getLocalNavigationCollection(locale);

    const links: DKSpaceSource<Link>[] = [];
    const linksToLoadFromGlobal: StringMap = {};

    const nonEmptyNavigationItems = localNavigationItems.filter(linkNotEmpty);
    const localNavigationItemsWithLinkRefs = nonEmptyNavigationItems.map(
      removeLocalizedLinkProperties
    );

    // We add the navigation items from Country
    nonEmptyNavigationItems
      .map((item: NavigationItem) => item.link as Link)
      .forEach((link: Link) => {
        if (shouldBeLoadedFromGlobal(link)) {
          linksToLoadFromGlobal[link.sys.id] = link.link as string;
        }

        links.push({
          ...link,
          origin: DKSpaceOriginType.LOCAL_SPACE,
        });
      });

    // We now load the links from Global which were defined locally but were not having a page
    if (Object.keys(linksToLoadFromGlobal).length) {
      const fallbackLinksFilter: LinkFilter = {
        link_in: Object.values(linksToLoadFromGlobal),
      };
      const $globalFallbackResponse = globalContentfulGraphQlClient().query({
        query: linkCollectionQuery,
        variables: {
          locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where: fallbackLinksFilter,
        },
      });
      // load global fallback locale pages as well
      const $globalFallbackWithFallbackLocaleResponse =
        globalContentfulGraphQlClient().query({
          query: linkCollectionQuery,
          variables: {
            locale: getEnv('I18N_GLOBAL_FALLBACK_LOCALE'),
            preview: getEnv('ENABLE_PREVIEW') === 'true',
            where: fallbackLinksFilter,
          },
        });

      // fetch all global links async
      const [globalFallbackResponse, globalFallbackWithFallbackLocaleResponse] =
        await Promise.all([
          $globalFallbackResponse,
          $globalFallbackWithFallbackLocaleResponse,
        ]);

      const globalFallbackItems =
        globalFallbackResponse.data.linkCollection.items.filter(linkNotEmpty);

      const globalFallbackWithFallbackLocaleItems =
        globalFallbackWithFallbackLocaleResponse.data.linkCollection.items.filter(
          linkNotEmpty
        );

      const globalFallbackLinks = getUniqueLinks(
        globalFallbackItems,
        globalFallbackWithFallbackLocaleItems
      );

      const updatePageAndOriginOfLocalLink = (link: Link) => {
        const localLinkId = Object.keys(linksToLoadFromGlobal).find(
          (key) => linksToLoadFromGlobal[key] === link.link
        );
        const localLink = links.find(
          (item: Link) => item.sys.id === localLinkId
        );

        if (localLink) {
          localLink.page = link.page;
          localLink.origin = DKSpaceOriginType.GLOBAL_SPACE;
        }
      };

      globalFallbackLinks.forEach(updatePageAndOriginOfLocalLink);
    }

    localNavigationItemsWithLinkRefs.forEach((item) => {
      const popUp = item.popup?.node
        ? cleanNodeItem(item.popup)
        : item.localPopup;
      if (popUp) {
        commit('setPopupsCollection', {
          id: popUp.sys.id,
          data: popUp,
        });
      }
    });

    commit('setNavigations', localNavigations);
    commit(
      'setNavigationItems',
      localNavigationItemsWithLinkRefs as NavigationItem[]
    );

    commit('setLinks', links);
    commit('setCurrentLinkByPath', currentPath(payload.$route));
    commit('setReady');
  },
  async fetchLinkBySlug(
    { commit },
    payload: { path: string; locale?: LOCALE }
  ) {
    const where: LinkFilter = {
      link: `/${payload.path}`,
    };
    const response = await contentfulGraphQlClient().query({
      query: linkCollectionQuery,
      variables: {
        locale: payload.locale || 'en-US',
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });
    commit('setLink', response.data.linkCollection.items[0]);
  },
};
