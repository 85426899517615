import gql from 'graphql-tag';
export const localReferenceCollectionQuery = (referenceLink: string) => gql`
query (
  $locale: String = "en-US"
  $preview: Boolean = false
  $where: LocalReferenceFilter
  $limit: Int
) {
  localReferenceCollection(
    locale: $locale
    preview: $preview
    where: $where
    limit: $limit
  ) {
    items {
      sys {
        id
      }
      reference {
        node {
          __typename
          ... on ${referenceLink} {
            sys {
              id
            }
          }
        }
      }
      callToActionLink {
        link
        enLink: link(locale: "en-US")
        deLink: link(locale: "de-CH")
        label
      }
    }
  }
}`;
