import { CreateClientParams } from 'contentful';
import { capitalize } from 'lodash';
import { getEnv } from '@/env';

const globalContentfulConfig = (): CreateClientParams => ({
  space: getEnv('CONTENTFUL_GLOBAL_SPACE_ID') || '',
  accessToken: getEnv('CONTENTFUL_GLOBAL_ACCESS_TOKEN') || '',
  environment: getEnv('CONTENTFUL_GLOBAL_ENVIRONMENT') || 'master',
});

export const globalExtraToken = (): string => {
  const global = globalContentfulConfig();
  const extraTokens = {
    spaces: {
      [global.space]: global.accessToken,
    },
  };

  return btoa(JSON.stringify(extraTokens));
};

const isBranchEnvironment = (env: string) => {
  return new RegExp(/^(feature|fix)-.*$/).test(env);
};

export const resourceLink = (typeName: string): string => {
  const global = globalContentfulConfig();

  const environment = isBranchEnvironment(global.environment || '')
    ? 'Development'
    : capitalize(global.environment);

  return `${typeName}_${global.space}_${environment}`;
};

export const cleanResourceTypename = (resource: string): string =>
  resource?.split('_')[0];

export const cleanNodeItem = (item: any) => ({
  ...item.node,
  __typename: cleanResourceTypename(item.node?.__typename),
});

export const mapNodeItems = (itemList: any[]) => itemList.map(cleanNodeItem);
