<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { useLockScreen } from '@/composables/lock-screen';
import { useMediaQuery } from '@/composables/media-query';

import { getFocusable } from '@/helpers/accessibility';
import { isAbsoluteLink, openLink } from '@/helpers/link';
import { getDayFromDate } from '@/helpers/date';

import { DKPopup as DKPopupModel, DKPopupRecurrence } from '@/models/DKPopup';
import { LinkTargetType } from '@/models/LinkTargetType';
import { DKLink } from '@/models/DKLink';

import { DKIcon, DKImage, DKButton } from '@dormakaba/dormakaba-components';

interface PopupLocalStorage {
  id: string;
  date: string;
}

const isOpen = ref<boolean>(false);

const { isExtraSmall } = useMediaQuery();
const { setLockScreen } = useLockScreen(isOpen);

const popupSessionStorage = ref<string[]>([]);
const popupLocalStorage = ref<PopupLocalStorage[]>([]);
const modalContainerRef = ref<HTMLDivElement>();
const isVisible = ref<boolean>(false);
const focusable = ref<HTMLElement[]>();
const store = useStore();

const popupData = computed<DKPopupModel | null>(
  () => store.getters['navigation/popup']
);

const link = computed<DKLink | undefined>(() => popupData.value?.link);

const linkTarget = computed<LinkTargetType | undefined>(() => {
  if (!link.value) return;
  return isAbsoluteLink(link.value.link)
    ? LinkTargetType.BLANK
    : LinkTargetType.SELF;
});

const isEveryRecurrence = computed<boolean>(
  () => popupData.value?.recurrence === DKPopupRecurrence.EVERY
);

const isDayRecurrence = computed<boolean>(
  () => popupData.value?.recurrence === DKPopupRecurrence.DAY
);

const imageObjectFit = computed<string>(() =>
  isExtraSmall.value ? 'contain' : 'cover'
);

const handleClose = (callback?: () => void): void => {
  if (!popupData.value) return;
  const { id } = popupData.value;

  isVisible.value = false;
  callback && callback();
  isEveryRecurrence.value ? setSessionItem(id) : setLocalItem(id);

  setTimeout(() => {
    isOpen.value = false;
  }, 500);
};

const setFocus = (
  $event: KeyboardEvent,
  element: HTMLElement | undefined
): void => {
  $event.preventDefault();
  element?.focus();
};

const keydownListener = ($event: KeyboardEvent): void => {
  const keysList: { [key: string]: () => void } = {
    Tab: () => {
      const { target, shiftKey } = $event;

      if (focusable.value) {
        const firstElement = focusable.value[0];
        const lastElement = focusable.value.at(-1);

        shiftKey
          ? target === firstElement && setFocus($event, lastElement)
          : target === lastElement && setFocus($event, firstElement);
      }
    },
  };

  const keyCode: () => void = keysList[$event.code];
  keyCode && keyCode();
};

const setFocusOnFirstElement = (): void => {
  if (modalContainerRef.value) {
    focusable.value = Array.from(
      getFocusable(modalContainerRef.value)
    ) as HTMLElement[];

    focusable.value[0].focus();
  }
};

const handleClickLink = (): void => {
  const goToLink = (): void => {
    if (link.value) {
      openLink(link.value.link);
    }
  };

  linkTarget.value === LinkTargetType.SELF ? handleClose(goToLink) : goToLink();
};

const retrieveLocalPopupStorage = (): void => {
  try {
    const localPopupStorage = localStorage.getItem('popup');
    popupLocalStorage.value = localPopupStorage
      ? JSON.parse(localPopupStorage)
      : [];
  } catch {
    popupLocalStorage.value = [];
  }
};

const retrieveSessionPopupStorage = (): void => {
  try {
    const sessionPopupStorage = sessionStorage.getItem('popup');
    popupSessionStorage.value = sessionPopupStorage
      ? JSON.parse(sessionPopupStorage)
      : [];
  } catch {
    popupSessionStorage.value = [];
  }
};

const setSessionItem = (id: string): void => {
  popupSessionStorage.value.push(id);
  sessionStorage.setItem('popup', JSON.stringify(popupSessionStorage.value));
};

const setLocalItem = (id: string): void => {
  const now = new Date();
  const nowISO = now.toISOString();

  popupLocalStorage.value.push({ id, date: nowISO });
  localStorage.setItem('popup', JSON.stringify(popupLocalStorage.value));
};

const getSessionItem = (id: string): void => {
  retrieveSessionPopupStorage();
  isOpen.value = !popupSessionStorage.value.includes(id);
};

const getLocalItem = (id: string): void => {
  retrieveLocalPopupStorage();
  const savedPopupIndex = popupLocalStorage.value.findIndex(
    (item) => item.id === id
  );

  if (savedPopupIndex === -1) {
    isOpen.value = true;
    return;
  }

  const savedPopup = popupLocalStorage.value.splice(savedPopupIndex, 1)[0];
  const interval = isDayRecurrence.value ? 1 : 7;

  isOpen.value = localCondition(savedPopup.date, interval);
};

const handlePopupOpen = (): void => {
  if (!popupData.value) return;

  const { id } = popupData.value;
  isEveryRecurrence.value ? getSessionItem(id) : getLocalItem(id);
};

const localCondition = (date: string, interval: number): boolean => {
  const intervalSavedDate = new Date();
  const savedDate = new Date(date);
  const today = new Date();

  intervalSavedDate.setDate(savedDate.getDate() + interval);

  const intervalSavedDateStr = getDayFromDate(intervalSavedDate);
  const todayStr = getDayFromDate(today);

  return todayStr === intervalSavedDateStr || today > intervalSavedDate;
};

const cleanLocalStorage = (): void => {
  retrieveLocalPopupStorage();

  popupLocalStorage.value = popupLocalStorage.value.filter(
    (item) => !localCondition(item.date, 7)
  );

  popupLocalStorage.value.length
    ? localStorage.setItem('popup', JSON.stringify(popupLocalStorage.value))
    : localStorage.removeItem('popup');
};

onMounted(cleanLocalStorage);
onMounted(handlePopupOpen);

onBeforeUnmount(() => {
  setLockScreen(false);
});

watch(popupData, handlePopupOpen);

watch(
  () => isOpen.value,
  (val: boolean) => {
    if (val) {
      document.addEventListener('keydown', keydownListener);

      setTimeout(() => {
        isVisible.value = true;
        setFocusOnFirstElement();
      });

      return;
    }

    document.removeEventListener('keydown', keydownListener);
  }
);
</script>

<template>
  <div
    v-if="isOpen && popupData"
    :class="[
      'dk-modal',
      { 'is-visible': isVisible, 'dk-modal--only-text': !popupData.image },
    ]"
  >
    <div class="dk-modal__wrapper">
      <div
        :id="popupData.id"
        ref="modalContainerRef"
        class="dk-modal__container"
        role="dialog"
        :aria-modal="true"
        :aria-labelledby="`${popupData.id}_label`"
      >
        <div class="dk-modal__header">
          <button :title="$t('popup.modal_close')" @click="handleClose()">
            <DKIcon icon="close" :size="36" />
          </button>
        </div>
        <div class="dk-modal__body" tabindex="0">
          <div class="dk-modal__inner">
            <DKImage
              class="dk-modal__image"
              v-if="popupData.image"
              :url="popupData.image.url"
              :alt="popupData.image.title"
              stack="getPopupImageStack"
              :objectFill="imageObjectFit"
              :customSizes="true"
            />
            <div class="dk-modal__content">
              <div class="dk-modal__info-box">
                <p v-if="popupData.preTitle" class="dk-modal__pretitle">
                  {{ popupData.preTitle }}
                </p>
                <h2 :id="`${popupData.id}_label`" class="dk-modal__title">
                  {{ popupData.title }}
                </h2>
                <p v-if="popupData.description" class="dk-modal__description">
                  {{ popupData.description }}
                </p>
              </div>
              <DKButton
                class="dk-modal__btn-link"
                :href="popupData.link.link"
                :target="linkTarget"
                :label="popupData.link.label"
                @click.prevent="handleClickLink"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.dk-modal {
  & :is(.dk-modal__wrapper, .dk-modal__container) {
    @apply transition duration-400;
  }

  &:not(.is-visible) {
    & .dk-modal__wrapper {
      @apply bg-[transparent];
      @apply delay-100;
      @apply opacity-0;
    }

    & .dk-modal__container {
      @apply translate-y-6;
    }
  }

  &.dk-modal--only-text {
    & .dk-modal__container {
      @apply py-10;
      @apply sm:px-20;
    }

    & .dk-modal__inner {
      @apply p-4;

      @apply sm:flex;
      @apply sm:p-0;
    }

    & .dk-modal__content {
      @apply p-0;
    }

    & .dk-modal__pretitle {
      @apply text-h7 leading-h7;
    }

    & .dk-modal__title {
      @apply heading-4;
      @apply leading-h5 sm:text-h5;
    }
  }

  & .dk-modal__wrapper {
    z-index: 1003;
    @apply fixed;
    @apply bg-[#67676796];
    @apply h-full w-full;
    @apply left-0 top-0;
    @apply grid items-center;
  }

  & .dk-modal__container {
    @apply w-full;
    @apply delay-100;
    @apply bg-white;
    @apply h-[100dvh];
    @apply flex flex-col;
    @apply mx-auto;

    @apply sm:relative;
    @apply sm:aspect-[31/20];
    @apply sm:max-w-[644px];
    @apply sm:h-fit;

    @apply md:max-w-[744px];
    @apply lg:max-w-[844px];
  }

  & .dk-modal__header {
    @apply h-main-navbar;
    @apply grid place-content-center;

    @apply sm:absolute;
    @apply sm:h-auto;
    @apply sm:right-4 sm:top-4;

    & button:is(:focus, :focus-within) {
      @apply outline outline-2 outline-offset-2 outline-blue-corporate;
    }
  }

  & .dk-modal__body {
    @apply flex-1;
    @apply overflow-y-scroll;

    @apply sm:overflow-hidden;
  }

  & .dk-modal__inner {
    @apply min-h-full;
    @apply flex flex-col;

    @apply sm:grid;
    @apply sm:grid-cols-2;
  }

  & .dk-modal__content {
    @apply p-4;
    @apply flex flex-col;
    @apply items-center justify-center;
    @apply gap-y-4;
    @apply flex-1;

    @apply sm:gap-y-10;
    @apply sm:px-6 sm:py-10;
  }

  & .dk-modal__info-box {
    @apply flex flex-col;
    @apply gap-y-4;
    @apply text-center;
  }

  & .dk-modal__pretitle {
    @apply p-large;
    @apply font-familySemibold;
    @apply text-red-dk;
  }

  & .dk-modal__title {
    @apply text-h7 leading-h7;
    @apply font-familySemibold;

    @apply break-words hyphenate;
  }

  & .dk-modal__description {
    @apply p-small;
  }

  & .dk-modal__image {
    @apply aspect-[3/4];
    @apply h-[40vh] w-auto;

    @apply sm:aspect-auto;
    @apply sm:h-full;
  }

  & .dk-modal__btn-link {
    @apply w-fit;
  }
}
</style>
