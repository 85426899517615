import { Quote, Service } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKService } from '@/models/DKService';
import { DKAsset } from '@/models/DKAsset';
import { DKQuote } from '@/models/DKQuote';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { QuoteFactory } from '@/models/factories/QuoteFactory';

export class ServiceFactory extends GenericFactory<Service, DKService> {
  contentType = 'Service';
  requiredProperties = [
    'slug',
    'title',
    'description',
    'headerImage',
    'icon',
    'quotes',
  ];

  protected map(source: Service): DKService {
    let headerImage: DKAsset | undefined;
    let download: DKAsset | undefined;
    let metaImage: DKAsset | undefined;
    const quotes: DKQuote[] = [];

    if (source.headerImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        headerImage = assetFactory.create(source.headerImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.download) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        download = assetFactory.create(source.download);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Download Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.metaImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.quotesCollection?.items) {
      const quoteFactory = new QuoteFactory(this.locale);
      const items: Quote[] = source.quotesCollection.items as Quote[];
      items.forEach((item: Quote) => {
        try {
          quotes.push(quoteFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Quote');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKService, {
      ...source,
      headerImage,
      download,
      metaImage,
      quotes,
    });
  }
}
