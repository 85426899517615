import { DKTabItem } from '@/models/DKTabs';

export enum DKEventStatus {
  Upcoming = 'upcoming',
  Past = 'past',
}

export interface DKEventTabItem extends DKTabItem {
  id: DKEventStatus;
}
