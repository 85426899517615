import {
  GenericShelfFragment as LocalGenericShelfFragment,
  LocalReference,
} from 'types/contentful-api';
import { cleanNodeItem, mapNodeItems } from '@/helpers/resource-resolution';

import { CareerInsightPage } from 'types/global-contentful-api';
import { CareerInsightPageFactory } from '@/models/factories/CareerInsightPageFactory';
import { DKCareerInsightPage } from '@/models/DKCareerInsightPage';
import { DKExtendedGenericShelfElement } from '@/models/DKExtendedGenericShelfElement';
import { DKGenericShelfElement } from '@/models/DKGenericShelfElement';
import { DKGenericShelfFragment } from '@/models/DKGenericShelfFragment';
import { DKGenericShelfFragmentTypes } from '@/models/DKGenericShelfFragmentTypes';
import { DKLink } from '@/models/DKLink';
import { DKUseCase } from '@/models/DKUseCase';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { GenericShelfElementFactory } from '@/models/factories/GenericShelfElementFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { UseCaseFactory } from '@/models/factories/UseCaseFactory';

type GenericShelfFragment = LocalGenericShelfFragment & {
  globalGenericShelfElements: CareerInsightPage[] | undefined;
};

export class GenericShelfFragmentFactory extends GenericFactory<
  GenericShelfFragment | LocalGenericShelfFragment,
  DKGenericShelfFragment
> {
  contentType = 'GenericShelfFragment';
  requiredProperties = ['shortTitle'];

  protected map(source: GenericShelfFragment): DKGenericShelfFragment {
    const genericShelfElements: Array<
      DKGenericShelfElement | DKUseCase | DKCareerInsightPage
    > = [];
    const genericShelfElementFactory = new GenericShelfElementFactory(
      this.locale
    );
    const careerInsightPageFactory = new CareerInsightPageFactory(this.locale);
    const useCaseFactory = new UseCaseFactory(this.locale);

    if (source.genericShelfElementsCollection?.items) {
      const items: DKExtendedGenericShelfElement[] = source
        .genericShelfElementsCollection
        .items as DKExtendedGenericShelfElement[];

      const globalItems =
        mapNodeItems(source?.globalGenericShelfElements?.items || []) || [];

      [...items, ...globalItems].forEach(
        (
          element:
            | DKExtendedGenericShelfElement
            | CareerInsightPage
            | LocalReference
        ) => {
          try {
            const type = DKGenericShelfFragmentTypes[source.type || 'Generic'];
            switch (element.__typename) {
              case 'CareerInsightPage':
                genericShelfElements.push(
                  careerInsightPageFactory.create(element)
                );
                break;
              case 'LocalReference': {
                const reference = cleanNodeItem(element.reference);
                genericShelfElements.push(useCaseFactory.create(reference));
                break;
              }
              default:
                element = element as DKExtendedGenericShelfElement;
                element = {
                  ...element,
                  __typename: type,
                  genericElementLink: {
                    link: element.genericElementLink,
                    enLink: element.genericElementLink,
                    deLink: element.genericElementLink,
                    label: type,
                  } as DKLink,
                };

                genericShelfElements.push(
                  genericShelfElementFactory.create(element)
                );
            }
          } catch (e) {
            if (e instanceof PropertyRequiredError) {
              console.warn('Could not generate GenericShelfElement');
              console.warn(e);
            } else {
              throw e;
            }
          }
        }
      );
    }

    return Object.assign({} as DKGenericShelfFragment, {
      ...source,
      genericShelfElements,
    });
  }
}
