<template>
  <div class="service-nav">
    <nav
      class="service-nav__nav"
      itemscope
      itemtype="http://schema.org/SiteNavigationElement"
      :class="{ 'service-nav--is-footer': isFooter }"
    >
      <DKLink
        v-for="navigationTreeItem of navigationTree"
        :key="navigationTreeItem.id"
        :href="navigationTreeItem.path"
        @click.prevent="onClick(navigationTreeItem)"
        class="dk-link--title"
        :class="{
          'dk-link--inverted': isInverted,
        }"
        :type="type"
        itemprop="url"
      >
        <span itemprop="name">
          {{ navigationTreeItem.label }}
        </span>
      </DKLink>
    </nav>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { DKLink, DKIcon } from '@dormakaba/dormakaba-components';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { openLink } from '@/helpers/link';

@Component({
  components: {
    DKLink,
    DKIcon,
  },
})
export default class ServiceNavigation extends Vue {
  @Prop() navigationTree!: NavigationTree;
  @Prop() isInverted!: boolean;
  @Prop() isFooter!: boolean;

  get type(): string | null {
    return this.isFooter ? null : 'internal';
  }

  onClick(navigationTreeItem: NavigationTreeItem) {
    openLink(navigationTreeItem.path, {
      target: navigationTreeItem.target,
    });
  }
}
</script>

<style lang="postcss" scoped>
.service-nav {
  @apply flex justify-center;
}

.service-nav__nav {
  @apply flex flex-col;
}

.dk-link--title {
  @apply heading-4;
  @apply hyphenate;
  word-break: auto-phrase;
}

.service-nav--is-footer {
  @apply w-full;
  @apply grid grid-cols-2;
  @apply gap-x-4 gap-y-2;

  @apply md:gap-y-4;
  @apply lg:gap-6;

  & .dk-link--title {
    @apply font-familyLight;
    @apply text-s-p-small leading-s-p-small;

    @apply md:text-s-p-medium md:leading-s-p-medium;

    &:is(:hover, :focus, :active) > :first-child {
      @apply text-grey-frigate no-underline !important;
    }
  }
}
</style>
