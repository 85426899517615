import { useResizeObserver } from '@vueuse/core';
import { computed, onBeforeMount, ref } from 'vue';

type MediaQuery = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const mqScreens = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1280,
  xl: 1480,
  xxl: 1920,
} as const;

export const useMediaQuery = () => {
  const mqValues = Object.values(mqScreens);
  const mqMobile: MediaQuery[] = ['xs', 'sm'];
  const mqDesktop: MediaQuery[] = ['lg', 'xl', 'xxl'];

  const mqCurrent = ref<MediaQuery>('lg');

  const isExtraSmall = computed<boolean>(() => mqCurrent.value === 'xs');
  const isSmall = computed<boolean>(() => mqCurrent.value === 'sm');
  const isMobile = computed<boolean>(() => mqMobile.includes(mqCurrent.value));
  const isTablet = computed<boolean>(() => mqCurrent.value === 'md');
  const isDesktop = computed<boolean>(() =>
    mqDesktop.includes(mqCurrent.value)
  );

  const resize = () => {
    useResizeObserver(document.body, (entries) => {
      const { contentRect } = entries[0];
      const { width } = contentRect;

      mqCurrent.value = Object.entries(mqScreens).reduce(
        (ac: MediaQuery, [key, value], i) =>
          width >= value && width < mqValues[i + 1] ? (key as MediaQuery) : ac,
        'xxl'
      );
    });
  };

  onBeforeMount(resize);

  return { mqCurrent, isExtraSmall, isSmall, isMobile, isTablet, isDesktop };
};
